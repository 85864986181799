// Generated by .maintenance/generators/routes.js
import AppLocaleDefault from "src/noaccountcasino.se/locales/en.json";

export default [
  {
    name: "maintenance-en",
    path: "/",
    component: () => import("src/.maintenance/views/default.vue"),
    meta: {
      title: "noaccountcasino.se",
      localeLoader: () => new Promise(resolve => resolve(AppLocaleDefault))
    }
  },
  {
    name: "restricted-en",
    path: "/restricted",
    component: () => import("src/.maintenance/views/restricted.vue"),
    meta: {
      title: "noaccountcasino.se - restricted",
      localeLoader: () => new Promise(resolve => resolve(AppLocaleDefault))
    }
  },
  {
    name: "maintenance-sv",
    path: "/sv/",
    component: () => import("src/.maintenance/views/default.vue"),
    meta: {
      title: "noaccountcasino.se",
      localeLoader: () => import("../noaccountcasino.se/locales/sv.json")
    }
  },
  {
    name: "restricted-sv",
    path: "/sv/restricted",
    component: () => import("src/.maintenance/views/restricted.vue"),
    meta: {
      title: "noaccountcasino.se - restricted",
      localeLoader: () => import("../noaccountcasino.se/locales/sv.json")
    }
  },
  {
    path: "*",
    component: () => import("src/.maintenance/views/404.vue"),
    meta: {
      title: "noaccountcasino.se - 404"
    }
  }
];
